import { NgModule } from '@angular/core';
import { NgxCleaveDirective } from './ngx-cleave.directive';

@NgModule({
  imports: [],
  declarations: [NgxCleaveDirective],
  exports: [NgxCleaveDirective],
})
export class NgxCleaveDirectiveModule {
}
